<template>
    <el-dialog :visible.sync="visible" :title="title" width="400px" @close="closeDialog">
        <span>回收类别名称</span>
        <el-input size="small" v-model="cName"></el-input>

        <div class="handle_buttons">
            <el-button size="small" type="primary" @click="save">确定</el-button>
            <el-button size="small" @click="visible = false">取消</el-button>
        </div>
    </el-dialog>
</template>

<script>
    export default {
        methods: {
            closeDialog() {
                this.cName = "";
                this.recycleTId = "";
                this.pRecycleTId = "";
            },
            init(option) {
                if (option) {
                    this.$nextTick(() => {
                        this.recycleTId = option.recycleTId;
                        this.cName = option.cName;
                        this.pRecycleTId = option.pRecycleTId;
                    });
                    this.title = "编辑回收类别";
                } else {
                    this.title = "新增回收类别";
                }
                this.visible = true;
            },
            save() {
                if (this.cName) {
                    let url = "/recycleType/add";
                    if (this.recycleTId) {
                        url = "/recycleType/update";
                    }
                    this.$http
                        .post(url, {
                            cName: this.cName,
                            recycleTId: this.recycleTId,
                            pRecycleTId: this.pRecycleTId
                        })
                        .then(res => {
                            this.$message.success({
                                message: res.msg
                            });
                            this.visible = false;
                            this.$emit("update", res.detail);
                        });
                } else {
                    this.$message.warning({
                        message: "请填写回收类别名称"
                    });
                }
            }
        },
        data() {
            return {
                title: "",
                visible: false,

                cName: "",
                recycleTId: "",
                pRecycleTId: ""
            };
        }
    };
</script>
<style scoped>
    .el-input {
        width: 180px;
        margin-left: 10px;
    }
    .handle_buttons {
        text-align: center;
        margin-top: 10px;
    }
</style>
