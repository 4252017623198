<template>
    <div>
        <div>
            <el-button size="small" @click="collapseAll(data)">折叠所有</el-button>
            <el-button size="small" @click="add" v-if="btnexist('wastesBasicInfoRecycleInsert')">新增回收类别</el-button>
        </div>
        <el-tree ref="recycleTTree" :props="props" :data="data" node-key="recycleTId" :expand-on-click-node="false" :render-content="renderContent"></el-tree>

        <add-update ref="addUpdate" @update="updateFun"></add-update>
    </div>
</template>

<script>
    import AddUpdate from "@/components/pages/admin/basic/recycle/AddUpdate.vue";
    import { btnMixins } from "@/common/js/button.mixin";
    export default {
        created() {
            this.getListAll();
        },
        mixins: [btnMixins],
        methods: {
            collapseAll(data) {
                data.forEach(el => {
                    if (this.$refs.recycleTTree.store.nodesMap[el.recycleTId].expanded) {
                        this.$refs.recycleTTree.store.nodesMap[el.recycleTId].expanded = false;
                        el.children && el.children.length > 0 ? this.collapseAll(el.children) : ""; // 子级递归
                    }
                });
            },
            getListAll() {
                this.$http.post("/recycleType/listTree").then(res => {
                    this.data = [];
                    this.handleRecycleTypes(res.detail);
                });
            },
            updateFun(detail){
                if (!detail.pRecycleTId) {
                    let o = this.findNodeById(this.data,detail.recycleTId)
                    if (!o) {
                        this.data.unshift(detail)
                    } else{
                        o.cName = detail.cName
                    }
                }else{
                    let p = this.findNodeById(this.data,detail.pRecycleTId)
                    let pn = this.$refs.recycleTTree.getNode(p)
                    if (p.children) {
                        let o = this.findNodeById(p.children,detail.recycleTId)
                        if (!o) {
                            p.children.push(detail)
                            this.$refs.recycleTTree.append(detail,pn)
                        } else{
                            o.cName = detail.cName
                        }
                    }else{
                        this.$set(p,'children',[])
                        p.children.push(detail)
                    }
                }
                this.$forceUpdate()
            },
            removeNode(detail){
                let list = this.data
                let p
                if (detail.pRecycleTId) {
                    p = this.findNodeById(this.data,detail.pRecycleTId)
                    list = p.children
                }
                let key
                list.forEach((one,k)=>{
                    if (one.recycleTId == detail.recycleTId) {
                        key = k
                    }
                })
                this.$refs.recycleTTree.remove(detail)
                list.splice(key,1)
                this.$forceUpdate()
            },
            findNodeById(list,id){
                let node = null
                for (var i = list.length - 1; i >= 0; i--) {
                    let item = list[i]
                    if (item.recycleTId == id) {
                        node = item
                    }else{
                        if (item.children) {
                            node = this.findNodeById(item.children,id)
                        }
                    }
                    if (node) {
                        break
                    }
                }
                return node
            },
            handleRecycleTypes(menus, pmenu) {
                menus.forEach(v => {
                    if (v.isDel !== "1") {
                        const item = {
                            cName: v.cName,
                            recycleTId: v.recycleTId,
                            pRecycleTId: v.pRecycleTId
                        };
                        if (pmenu) {
                            if (!pmenu.children) {
                                pmenu.children = [];
                            }
                            pmenu.children.push(item);
                        } else {
                            this.data.push(item);
                        }
                        if (v.children) {
                            this.handleRecycleTypes(v.children, item);
                        }
                    }
                });
            },
            add(node) {
                if (node && node.recycleTId) {
                    this.$refs.addUpdate.init({ pRecycleTId: node.recycleTId });
                } else {
                    this.$refs.addUpdate.init();
                }
            },
            remove({ data }) {
                if (data.children && data.children.length) {
                    this.$message({
                        type: "warning",
                        message: "不能删除有子类型的节点"
                    });
                } else {
                    this.$confirm("是否删除该回收类别?", "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning"
                    })
                        .then(() => {
                            this.$http.post("/recycleType/delete", data.recycleTId).then(res => {
                                this.$message.success({
                                    message: res.msg
                                });
                                // this.getListAll();
                                this.removeNode(data)
                            });
                        })
                }
            },
            edit(data) {
                this.$refs.addUpdate.init(data);
            },
            renderContent(h, { node, data }) {
                return (
                    <span class="custom-tree-node">
                        <span>{node.label}</span>
                        <span>
                            {this.btnexist("wastesBasicInfoRecycleInsert") ? (
                                <el-button class="node_button" icon="el-icon-plus" size="mini" type="text" on-click={() => this.add(data)}></el-button>
                            ) : (
                                ""
                            )}
                            {this.btnexist("wastesBasicInfoRecycleUpdate") ? (
                                <el-button class="node_button" icon="el-icon-edit" size="mini" type="text" on-click={() => this.edit(data)}></el-button>
                            ) : (
                                ""
                            )}
                            {this.btnexist("wastesBasicInfoRecycleDelete") ? (
                                <el-button class="node_button" icon="el-icon-delete" size="mini" type="text" on-click={() => this.remove(node, data)}></el-button>
                            ) : (
                                ""
                            )}
                        </span>
                    </span>
                );
            }
        },
        data() {
            return {
                data: [],

                props: {
                    label: "cName"
                },

                btnMenuId: "wastesBasicInfoRecycle"
            };
        },
        components: {
            AddUpdate
        }
    };
</script>
<style lang="scss" scoped>
    .el-tree ::v-deep .el-tree-node {
        margin: 10px 0;
        .el-tree-node__content {
            border: 1px solid #dcdcdc;
            height: 45px;
            .custom-tree-node {
                flex: 1;
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 14px;
                padding: 0 8px;
                height: 100%;
                .node_button {
                    border: 1px solid #dcdcdc;
                    padding: 5px;
                }
            }
        }
    }
</style>
